import flourish_app from  "../assets/images/projects/flourish.png";
import flourish_inter_app from  "../assets/images/projects/flourish-interface.png";
import photo_society from  "../assets/images/projects/photography-society.png";
import Skills from "./Skills";

function Projects() {
    const handleClickPhoto = () => {
        window.open("https://limerickps.atspace.eu/", "_blank");
    };

    const handleClickFyp = () => {
        window.open("https://github.com/kaflitun/flourish", "_blank");
    };

    return (
        <>
            <div className="section" id="projects">
                <div className="section-column heading-container">
                    <h1 className="section-heading">Projects</h1>
                    <p>Information about some of my projects</p>
                </div>
            </div>
            <div className="section-column heading-container mob-dev-heading section-dev-heading">
                <h2>Mobile Development</h2>
            </div>

            <div className="section subsection-container">
                <div className="section-column img-column">
                    <div className="section-row-two-images">
                        <div>
                            <img src={flourish_app} alt="Flourish mobile app" className="proj-img mobile-img" />
                        </div>
                        <div>
                            <img src={flourish_inter_app} alt="Flourish mobile app" className="proj-img mobile-img" />
                        </div>
                    </div>
                </div>
                <div className="section-column">
                    <h3 className="prj-heading"><i className="fa fa-chevron-right"></i>Flourish</h3>
                    <p className="even-text prj-desc-paragraph">
                        Mobile application that uses sensors and AI to improve a plant care routine by identifying
                        plant diseases and monitoring plant conditions. This solution aims to provide plant
                        owners with care recommendations based on gathered data about plant conditions to improve
                        plant care management and, as a result, plant health and growth.
                    </p>
                    <Skills items={["Kotlin", "C++", "MongoDB", "ESP32","NPK sensor",
                        "Google API","GPT API","Git","Figma","Firebase"]} />
                    <button onClick={handleClickFyp} className="btn btn-primary">View Project</button>
                </div>
            </div>
            <div className="section-column heading-container web-dev-heading section-dev-heading">
                <h2>Web Development</h2>
            </div>

            <div className="section subsection-container">
                <div className="section-column img-column">
                    <img src={photo_society} alt="Photography Society website" className="proj-img laptop-img" />
                </div>

                <div className="section-column">
                    <h3 className="prj-heading"><i className="fa fa-chevron-right"></i>Limerick photography society </h3>
                    <p className="even-text prj-desc-paragraph">
                        Limerick photography society website. The website is designed to showcase the work of the society members,
                        provide information about the society, and allow new members to join the society.
                    </p>
                    <Skills items={["HTML", "CSS", "JavaScript"]} />
                    <button onClick={handleClickPhoto} className="btn btn-primary">View Project</button>
                </div>
            </div>
        </>
    );
}

export default Projects;