import React, { useState } from 'react';

function Header() {
    const [activeLink, setActiveLink] = useState('Home');

    const handleSetActive = (linkName) => {
        setActiveLink(linkName);
    };

    return (
        <header>
            <nav className="navbar navbar-expand-lg">
                <div className="container-fluid">
                    <button className="navbar-toggler ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav">
                            <li className="nav-item mx-3">
                                <a
                                    className={`nav-link ${activeLink === 'Home' ? 'active' : ''}`}
                                    href="/"
                                    onClick={() => handleSetActive('Home')}
                                >
                                    Home
                                </a>
                            </li>
                            <li className="nav-item mx-3">
                                <a
                                    className={`nav-link ${activeLink === 'About' ? 'active' : ''}`}
                                    href="#about"
                                    onClick={() => handleSetActive('About')}
                                >
                                    About
                                </a>
                            </li>
                            <li className="nav-item mx-3">
                                <a
                                    className={`nav-link ${activeLink === 'Projects' ? 'active' : ''}`}
                                    href="#projects"
                                    onClick={() => handleSetActive('Projects')}
                                >
                                    Projects
                                </a>
                            </li>
                        </ul>
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item">
                                <a className="nav-link mx-3" aria-current="page" href="mailto:kaflitunova@gmail.com">
                                    <i className='fas fa-envelope '></i>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link mx-3" aria-current="page" href="https://linkedin.com/in/ksenia-aflitunova" target="_blank">
                                    <i className='fab fa-linkedin'></i>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link mx-3" aria-current="page" href="https://github.com/kaflitun" target="_blank">
                                    <i className='fab fa-github'></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default Header;