import Skills from "./Skills";

function About(){
    const languages = [
        "Java (Spring)",
        "C# (.NET)",
        "C++",
        "Python",
        "JS (React)",
        "Kotlin",
        "PHP",
        "SQL",
        "CSS",
        "HTML"
    ];
    const tools = [
        "Docker",
        "Git",
        "GitLab",
        "AWS",
        "Linux",
        "Bash",
        "Postman",
        "RapidMiner"
    ];

    const databases = [
        "MySQL",
        "MongoDB"
    ];

    return(
        <>
            <div className="backgrnd-img">
            <div className="section" id="about">
                <div className="section-column heading-container">
                    <h1 className="section-heading">About Me</h1>
                    <p>Information about my skills and background</p>
                </div>
            </div>
            <div className="section subsection-container">
                    <div className="section-column">
                        <h2>My background</h2>
                        <p className="even-text">I am a recent graduate with First Class Honours
                            in Software Development from the Technological University of the Shannon, Limerick.
                            My education, combined with hands-on experience from internships and personal projects,
                            has equipped me with a strong foundation in both frontend and backend development.</p>
                        <p className="even-text">
                            I completed an internship at Jaguar Land Rover, where I worked on the project related to vehicle safety.
                            This experience strengthened my skills in software testing, debugging, and development,
                            and enhanced my problem-solving abilities in practical applications.
                        </p>
                        <p className="even-text">
                            I have also been recognised with several academic awards, highlighting my commitment to excellence
                            and passion for software development.
                            These achievements inspire me to keep learning and to contribute to impactful projects in the tech industry.
                        </p>
                    </div>
                    <div className="section-column">
                        <h2>Skills</h2>
                        <h3 id="lang-heading"><i className="fa fa-chevron-right"></i>Languages</h3>
                        <Skills items={languages}/>
                        <h3><i className="fa fa-chevron-right"></i>Databases</h3>
                        <Skills items={databases}/>
                        <h3><i className="fa fa-chevron-right"></i>Tools</h3>
                        <Skills items={tools}/>
                    </div>
                </div>
            </div>
        </>
    );
}
export default About