import dev_img from  "../assets/images/dev.png";
import cv from "../assets/docs/Ksenia_Aflitunova.pdf";

function Home() {
    return (
        <div className="section" id="home">
            <div className="section-column" id="text-col">
                <h1 id="main-heading"><p>Hey, I'm </p> <p id="name">Ksenia Aflitunova</p> <p id="occupation">Software Developer</p></h1>
                <p className="even-text">I'm a recent graduate passionate about building impactful and innovative applications.
                    With hands-on experience in both frontend and backend,
                    I specialise in creating responsive web design and efficient APIs.
                    I also have experience working with embedded systems, mobile applications,
                    machine learning and data visualisation.
                    I’m excited to take on new challenges and contribute to forward-thinking projects.</p>
                <a href={cv} download="Ksenia_Aflitunova_CV"  className="btn btn-primary">
                    Download CV
                    {/*<button type="button" className="btn btn-primary">Download CV</button>*/}
                </a>
            </div>
            <div className="section-column" id="img-col">
                <img src={dev_img} alt="Girl writing code" id="dev-img"></img>
            </div>
        </div>
    );
}
export default Home